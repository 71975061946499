@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600,700,800,900");
body {
  margin: 0;
  font-family: Roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 1000px) {
  html {
    font-size: 4px;
  }
}
@media screen and (min-width: 1001px) and (max-width: 1279px) {
  html {
    font-size: 6px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1919px) {
  html {
    font-size: 8px;
  }
}
@media screen and (min-width: 1920px) and (max-width: 2561px) {
  html {
    font-size: 10px;
  }
}
@media screen and (min-width: 2560px) and (max-width: 3839px) {
  html {
    font-size: 16px;
  }
}
@media screen and (min-width: 3840px) {
  html {
    font-size: 20px;
  }
}
