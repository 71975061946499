@font-face {
  font-family: "InriaSans-Bold";
  src: url("../assets/InriaSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "InriaSans-Light";
  src: url("../assets/InriaSans-Light.ttf") format("truetype");
}
@font-face {
  font-family: "InriaSans-Regular";
  src: url("../assets/InriaSans-Regular.ttf") format("truetype");
}