@import url("https://fonts.googleapis.com/css2?family=Crimson+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600,700,800,900");

@font-extrabold: 700;
@font-bold: 600;
@font-semibold: 500;
@font-medium: 400;
@font-regular: 300;
@font-light: 200;

.forMobilePic {
  width: 100%;
  height: 100%;
}

.main {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #2a292a;
  overflow: hidden;
  .mainHeader {
    height: 5%;
    display: flex;
    justify-content: space-between;
    background-color: #1f1f1f;
    padding: 1rem 2rem;

    .mainHeaderLeft {
      height: 100%;
      cursor: pointer;
      .mainHeaderLeftIcon {
        width: 4.64rem;
        height: 4.64rem;
        margin-right: 1rem;
        vertical-align: bottom;
      }
      .mainHeaderLeftText {
        font-family: "Crimson Pro";
        height: 100%;
        display: inline-block;
        font-size: 4.63rem;
        color: white;
        font-weight: @font-bold;
      }
    }
    .mainHeaderMiddle {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 1.6rem;

      .middleBtn {
        height: 100%;
        font-family: "InriaSans-Bold";
        cursor: pointer;
        font-size: 2.2rem;
        color: white;
        text-align: center;

        .middleBtnIcon {
          width: 2.34rem;
          margin-left: 1rem;
        }
      }
    }
    .mainHeaderRight {
      display: flex;
      .mainHeaderRightIcon2 {
        width: 4rem;
        height: 4rem;
        margin-top: 0.4rem;
        cursor: pointer;
        margin-right: 2rem;
      }

      .mainHeaderRightBtn {
        width: 28.7rem;
        height: 4.8rem;
        color: #ffffff;
        text-align: center;
        padding-top: 1.4rem;
        background-color: #0085ff;
        border-radius: 0.6rem;
        cursor: pointer;
        font-size: 3rem;
        font-weight: bold;

        &:hover {
          background-color: #67b6ff;
        }
      }
    }
  }

  .mainContainer {
    .home {
      .homeTop {
        margin-left: 28rem;
        height: 114rem;
        display: flex;
        background-color: #2a292a;

        .homeTopLeft {
          margin-top: 20rem;
          z-index: 99;
          .homeTopLeftTitle {
            font-size: 5.96rem;
            font-weight: @font-extrabold;
            color: #ffffff;
            .homeTopLeftBlueTitle {
              color: #2898ff;
            }
          }

          .homeTopLeftText1 {
            font-size: 3.97rem;
            font-weight: @font-bold;
            color: #a0a0a0;
            margin-top: 6rem;
          }

          .homeTopLeftText2 {
            font-size: 3.97rem;
            font-weight: @font-bold;
            color: #a0a0a0;
            margin-top: 6rem;
          }

          .homeTopLeftBtn {
            margin-top: 6rem;
            margin-bottom: 26.8rem;
            width: 28.7rem;
            height: 4.7rem;
            padding-top: 1.5rem;
            font-size: 2.64rem;
            font-weight: @font-extrabold;
            background-color: #0085ff;
            color: #ffffff;
            border-radius: 0.6rem;
            text-align: center;
            cursor: pointer;

            &:hover {
              background-color: #67b6ff;
            }
          }
        }

        .homeTopRightPic {
          width: 90rem;
          height: 90rem;
          z-index: 1;
          position: absolute;
          top: 17rem;
          right: 0;
        }
      }

      .homeSecond {
        height: 11.5rem;
        padding-top: 7rem;
        background-color: #1d1d1d;
        display: flex;
        justify-content: space-around;

        .homeSecondLeft {
          margin-left: 16rem;
          font-size: 2.98rem;
          font-family: "InriaSans-Bold";
          color: #ffffff;
        }
        .homeSecondMiddle {
          font-size: 2.98rem;
          font-family: "InriaSans-Bold";
          color: #ffffff;
          text-align: center;
          .homeSecondMiddleBlue {
            color: #2898ff;
          }
        }
        .homeSecondRight {
          cursor: pointer;
          width: 28.7rem;
          height: 4.7rem;
          padding-top: 1.5rem;
          border-radius: 0.6rem;
          color: #ffffff;
          background-color: #0085ff;
          font-size: 2.64rem;
          font-weight: @font-extrabold;
          text-align: center;

          &:hover {
            background-color: #67b6ff;
          }
        }
      }

      .homeThird {
        height: 74.1rem;
        background-color: #111111;
        padding: 11.1rem 28rem;
        color: #ffffff;

        .homeThirdTop {
          font-size: 3.97rem;
          font-family: "InriaSans-Bold";
          text-align: center;
        }

        .homeThirdMiddle {
          margin-top: 4.5rem;
          font-size: 2.3rem;
          font-family: "InriaSans-Regular";
          text-align: center;
        }

        .homeThirdBottom {
          margin-top: 18rem;
          display: flex;
          justify-content: space-between;

          .homeThirdBottomItem {
            width: 25%;
            .homeThirdBottomItemTitle {
              font-size: 3.97rem;
              font-family: "InriaSans-Bold";
              margin-bottom: 4rem;
            }
            .homeThirdBottomItemText {
              font-size: 2.3rem;
              font-family: "InriaSans-Regular";
            }
          }
        }
      }

      .homeFourth {
        height: 135.9rem;
        background-color: #1d1d1d;
        display: flex;

        .homeFourthLeft {
          z-index: 999;
          margin-left: 27.8rem;
          .homeFourthLeftTitle {
            width: 38rem;
            margin-top: 6.6rem;
            font-size: 11.59rem;
            color: #ffffff;
            font-weight: @font-bold;
          }
          .homeFourthLeftSubTitle {
            margin-top: 6rem;
            font-size: 4.96rem;
            color: #a0a0a0;
            font-weight: @font-bold;
          }
          .homeFourthLeftText {
            margin-top: 3.7rem;
            width: 57.3rem;
            font-size: 2.98rem;
            font-weight: @font-medium;
            color: #ffffff;
          }
          .homeFourthLeftBtn {
            margin-top: 5.6rem;
            width: 28.7rem;
            height: 4.8rem;
            color: #ffffff;
            text-align: center;
            padding-top: 1.4rem;
            background-color: #0085ff;
            border-radius: 0.6rem;
            cursor: pointer;
            font-size: 2.64rem;
            font-weight: @font-extrabold;

            &:hover {
              background-color: #67b6ff;
            }
          }
        }
        .homeFourthRight {
          position: absolute;
          right: 0;
          width: 110rem;
          height: 79rem;
          z-index: 1;
        }
      }

      .homeFifth {
        position: relative;
        background-color: #ffffff;
        height: 97rem;
        padding-top: 25.7rem;

        .homeFifthPic1 {
          height: 79rem;
          position: absolute;
          top: -37rem;
          z-index: 1;
        }
        .homeFifthPic2 {
          cursor: pointer;
          height: 52rem;
          width: 52rem;
          position: absolute;
          top: -23rem;
          margin-left: 50%;
          left: -25rem;
          z-index: 1;
          &:hover {
            transform: scale(1.1);
            transition-duration: 150ms;
          }
        }
        .homeFifthPic3 {
          height: 78rem;
          position: absolute;
          top: -36rem;
          right: 0;
          z-index: 1;
        }
        .homeFifthPic4 {
          cursor: pointer;
          position: absolute;
          width: 18.3rem;
          height: 15.5rem;
          left: 5.4rem;
          z-index: 2;
          top: -19.9rem;
          &:hover {
            transform: scale(1.1);
            transition-duration: 150ms;
          }
        }
        .homeFifthPic5 {
          cursor: pointer;
          position: absolute;
          width: 17rem;
          height: 17rem;
          left: 19.8rem;
          z-index: 2;
          top: 5.1rem;
          &:hover {
            transform: scale(1.1);
            transition-duration: 150ms;
          }
        }
        .homeFifthPic6 {
          cursor: pointer;
          position: absolute;
          width: 14rem;
          height: 14rem;
          left: 40.8rem;
          z-index: 999;
          top: -9.3rem;
          &:hover {
            transform: scale(1.1);
            transition-duration: 150ms;
          }
        }
        .homeFifthPic7 {
          cursor: pointer;
          position: absolute;
          width: 23rem;
          height: 18rem;
          right: 38rem;
          z-index: 2;
          top: -10.3rem;
          &:hover {
            transform: scale(1.2);
          }
        }
        .homeFifthPic8 {
          cursor: pointer;
          position: absolute;
          width: 29rem;
          height: 28rem;
          right: 13.5rem;
          z-index: 2;
          top: 0.7rem;
          &:hover {
            transform: scale(1.1);
            transition-duration: 150ms;
          }
        }
        .homeFifthPic9 {
          cursor: pointer;
          position: absolute;
          width: 28rem;
          height: 29rem;
          right: 0.9rem;
          z-index: 2;
          top: -23rem;
          &:hover {
            transform: scale(1.1);
            transition-duration: 150ms;
          }
        }

        .homeFifthTitle {
          color: #000000;
          font-weight: @font-bold;
          font-size: 11.59rem;
          text-align: center;
        }

        .homeFifthSubTitle {
          text-align: center;
          margin-top: 2.4rem;
          color: #888888;
          font-weight: @font-bold;
          font-size: 4.9rem;
        }

        .homeFifthTextContainer {
          text-align: center;
          margin-top: 5.26rem;
          padding: 0 20%;
          color: #323232;
          font-size: 2.98rem;
          font-family: "InriaSans-Regular";
          text-align: center;
          white-space: pre-wrap;
          word-break: break-word;
          .homeFifthTextContainermb6 {
            margin-bottom: 6rem;
          }
        }

        .homeFifthBtnFather {
          text-align: center;
          .homeFifthBtn {
            display: inline-block;
            margin-top: 8.1rem;
            width: 28.7rem;
            height: 4.8rem;
            color: #ffffff;
            text-align: center;
            padding-top: 1.4rem;
            background-color: #0085ff;
            border-radius: 0.6rem;
            cursor: pointer;
            font-size: 2.64rem;
            font-weight: @font-extrabold;

            &:hover {
              background-color: #67b6ff;
            }
          }
        }
      }
    }

    .optimaAnything {
      background-color: #ffffff;
      .optimaAnythingFirst {
        height: 63.5rem;
        background-color: #000000;
        padding-left: 33.2rem;
        padding-top: 26.3rem;
        .optimaAnythingFirstTitle {
          font-size: 11.59rem;
          font-weight: @font-bold;
          color: #ffffff;
        }
        .optimaAnythingFirstSubTitle {
          font-size: 5.96rem;
          font-weight: @font-bold;
          color: #8c8c8c;
          margin-left: 75.8rem;
          margin-top: 3rem;
        }
        .optimaAnythingFirstText {
          color: #ffffff;
          font-size: 2.98rem;
          font-family: "InriaSans-Regular";
          margin-top: 8.7rem;
        }
      }

      .optimaAnythingSecond {
        padding: 16.4rem 27.3rem 33.1rem 33.2rem;

        .optimaAnythingSecondType1 {
          display: flex;
          justify-content: space-between;
          .optimaAnythingSecondPic1 {
            width: 53.5rem;
            height: 53.9rem;
          }
          .optimaAnythingSecondText1 {
            font-size: 2.98rem;
            font-family: "InriaSans-Regular";
            color: #424242;

            .optimaAnythingSecondTitle {
              font-size: 4.8rem;
              font-family: "InriaSans-Bold";
              margin-bottom: 4rem;
            }

            .mt2 {
              margin-top: 2.5rem;
            }

            .mt4 {
              margin-top: 4rem;
            }

            .mt5 {
              margin-top: 6rem;
            }

            .mt6 {
              margin-top: 8rem;
            }
          }
        }

        .mt20 {
          margin-top: 20rem;
        }
      }
    }

    .getAccess {
      .getAccessFirst {
        position: relative;
        height: 136.1rem;
        background-color: #000000;
        padding-top: 25.2rem;

        .getAccessFirstTitle {
          text-align: center;
          color: #ffffff;
          font-size: 11.59rem;
          font-weight: @font-bold;
        }

        .getAccessFirstText {
          margin-top: 6.3rem;
          color: #ffffff;
          font-size: 2.98rem;
          font-weight: @font-medium;
          text-align: center;
        }

        .getAccessFirstForm {
          margin-top: 7.4rem;
          text-align: center;

          .getAccessFirstFormTitle1 {
            margin-bottom: 1.4rem;
            color: #ffffff;
            font-size: 2.98rem;
            font-weight: @font-medium;
            text-align: center;
          }
          .getAccessFirstFormInput {
            width: 82.3rem;
            height: 6.5rem;
            font-size: 2.98rem !important;
            font-weight: @font-medium !important;
            font-family: Roboto !important;
            border-radius: 1.2rem !important;
          }
          .getAccessFirstFormTitle2 {
            margin-top: 4.3rem;
            margin-bottom: 1.4rem;
            color: #ffffff;
            font-size: 2.98rem;
            font-weight: @font-medium;
            text-align: center;
          }

          .submitBtn {
            margin-top: 6.2rem;
            width: 28.7rem;
            height: 4.7rem;
            color: #ffffff;
            text-align: center;
            padding-top: 1.5rem;
            background-color: #0085ff;
            border-radius: 0.6rem;
            cursor: pointer;
            font-size: 2.98rem;
            font-weight: @font-medium;
            margin-left: 50%;
            position: relative;
            left: -13.3rem;

            &:hover {
              background-color: #67b6ff;
            }
          }
        }

        .wordMap {
          width: 123.4rem;
          height: 53.9rem;
          position: absolute;
          left: 50%;
          margin-left: -60rem;
          bottom: -30rem;
          z-index: 999;
          cursor: pointer;
          &:hover {
            transform: scale(1.1);
            transition-duration: 150ms;
          }
        }
      }

      .getAccessSecond {
        height: 104.5rem;
        padding-top: 39.2rem;
        color: #ffffff;
        text-align: center;

        .getAccessSecondTitle {
          font-size: 11.59rem;
          font-weight: @font-bold;
          margin-bottom: 3.5rem;
        }

        .getAccessSecondSubTitle {
          font-size: 4.96rem;
          font-weight: @font-bold;
          color: #a0a0a0;
          margin-bottom: 7.5rem;
        }

        .getAccessSecondText {
          font-size: 2.98rem;
          font-family: "InriaSans-Regular";

          .mb3 {
            margin-bottom: 2.98rem;
          }
        }
      }

      .getAccessThird {
        height: 172.4rem;
        background: #ffffff;
        padding-top: 9rem;

        .getAccessThirdRow {
          display: flex;
          justify-content: center;
          margin-bottom: 6rem;
          .getAccessThirdRowItem {
            width: 57.8rem;
            height: 78.7rem;
            background-color: #2a292a;
            text-align: center;

            .getAccessThirdRowItemPic1 {
              width: 43rem;
              height: 24rem;
              margin-top: 4.2rem;
              margin-bottom: 8rem;
            }
            .getAccessThirdRowItemPic2 {
              width: 28rem;
              height: 25rem;
              margin-top: 4.2rem;
              margin-bottom: 7rem;
            }
            .getAccessThirdRowItemPic3 {
              width: 34rem;
              height: 19rem;
              margin-top: 4.2rem;
              margin-bottom: 10rem;
            }
            .getAccessThirdRowItemPic4 {
              width: 30rem;
              height: 20rem;
              margin-top: 4.2rem;
              margin-bottom: 9.4rem;
            }

            .getAccessThirdRowItemTitile {
              margin-bottom: 4rem;
              font-size: 4.8rem;
              font-weight: @font-bold;
              color: #ffffff;
            }

            .getAccessThirdRowItemText {
              color: #ffffff;
              font-size: 2.5rem;
              font-family: "InriaSans-Regular";
            }
          }
        }
      }
    }

    .dream {
      .dreamFirst {
        height: 63.5rem;
        padding-top: 26.3rem;
        padding-left: 33.2rem;
        background-color: #000000;

        .dreamFirstTitle {
          font-size: 11.59rem;
          font-weight: @font-bold;
          color: #ffffff;
        }
        .dreamFirstSubTitle {
          font-size: 5.96rem;
          font-weight: @font-bold;
          color: #8c8c8c;
          margin-left: 75.8rem;
          margin-top: 3rem;
        }
        .dreamFirstText {
          color: #ffffff;
          font-size: 2.98rem;
          font-family: "InriaSans-Regular";
          margin-top: 8.7rem;
        }
      }

      .dreamSecond {
        background-color: #ffffff;

        .dreamSecondType1 {
          display: flex;
          justify-content: space-between;
          .dreamSecondPic1 {
            width: 80rem;
            height: 96.8rem;
          }
          .dreamSecondText1 {
            margin: 10rem 15rem 5.8rem 17rem;
            font-size: 2.5rem;
            font-family: "InriaSans-Regular";
            color: #424242;

            .dreamSecondText1Mb {
              margin-bottom: 3rem;
            }
          }
        }
      }
    }

    .dreamThird {
      height: 90.6rem;
      padding-top: 17.8rem;
      background-color: #000000;
      text-align: center;

      .dreamThirdTitle {
        font-size: 11.59rem;
        font-weight: @font-bold;
        color: #ffffff;
        text-align: center;
      }

      .dreamThirdText {
        margin-top: 9rem;
        font-size: 2.5rem;
        font-family: "InriaSans-Regular";
        color: #ffffff;

        .dreamThirdTextMb {
          margin-bottom: 3rem;
        }
      }
    }

    .earlyStage {
      .earlyStageFirst {
        height: 29.2rem;
        color: #ffffff;
        padding-top: 29.5rem;
        padding-left: 33.7rem;
        padding-bottom: 32rem;
        font-size: 11.59rem;
        font-weight: @font-bold;
        color: #ffffff;
        .earlyStageFirstTitle1 {
          margin-bottom: 2.2rem;
        }
        .earlyStageFirstTitle2 {
          margin-left: 40.1rem;
        }
      }

      .earlyStageSecond {
        height: 123.9rem;
        color: #ffffff;
        background-color: #1d1d1d;
        display: flex;
        justify-content: space-between;

        .earlyStageSecondLeft {
          padding: 29.4rem 0 36.7rem 22.9rem;
          .earlyStageSecondLeftTitle {
            font-size: 14rem;
            font-weight: @font-bold;
          }

          .earlyStageSecondLeftSubTitle {
            margin-top: 5.8rem;
            font-size: 4.96rem;
            font-size: @font-bold;
            color: #a0a0a0;
          }

          .earlyStageSecondLeftText {
            margin-top: 5.8rem;
            font-size: 2.9rem;
            font-family: "InriaSans-Regular";

            .mb3 {
              margin-bottom: 3rem;
            }
          }
        }

        .earlyStageSecondRightPic {
          width: 102.4rem;
          height: 123.9rem;
        }
      }

      .earlyStageThird {
        height: 123.9rem;
        color: #000000;
        background-color: #ffffff;
        display: flex;
        justify-content: space-between;

        .earlyStageThirdRight {
          padding: 46rem 22.7rem 0 0;
          .earlyStageThirdRightTitle {
            font-size: 14rem;
            font-weight: bold;
          }

          .earlyStageThirdRightSubTitle {
            margin-top: 5.8rem;
            font-size: 4.96rem;
            font-size: @font-bold;
            color: #a0a0a0;
          }
        }

        .earlyStageThirdLeftPic {
          width: 102.4rem;
          height: 123.9rem;
        }
      }
    }

    .mainFooter {
      height: 42.2rem;
      padding-top: 15rem;
      background-color: #000000;

      .footerText {
        font-size: 4.96rem;
        font-weight: @font-bold;
        color: white;
        text-align: center;
      }

      .footerBtn {
        cursor: pointer;
        width: 28.7rem;
        height: 4.7rem;
        padding-top: 1.5rem;
        border-radius: 0.6rem;
        color: #000000;
        background-color: #ffffff;
        font-size: 2.64rem;
        font-weight: @font-extrabold;
        text-align: center;
        margin-top: 7.6rem;
        position: relative;
        left: 50%;
        margin-left: -15rem;
      }

      .footerBottom {
        position: relative;
        margin-top: 8rem;
        .footerBottomLeft {
          cursor: pointer;
          margin-left: 7.2rem;
          display: inline-block;

          .footerBottomLeftIcon {
            width: 8.3rem;
            height: 8.3rem;
            margin-right: 2rem;
            vertical-align: bottom;
          }
          .footerBottomLeftText {
            font-family: "Crimson Pro";
            height: 100%;
            display: inline-block;
            font-size: 8.28rem;
            color: white;
            font-weight: @font-bold;
          }
        }

        .footerBottomMiddle {
          display: inline-block;
          font-size: 2.98rem;
          font-weight: @font-medium;
          color: #ffffff;
          position: absolute;
          left: 50%;
          bottom: 1.6rem;
          margin-left: -12.5rem;
        }
      }
    }
  }
  .blueColor {
    color: #2898ff;
  }
  .mr75 {
    margin-right: 7.5rem;
  }
}

.headerBtnOptText {
  font-size: 2.2rem !important;
  font-family: Roboto !important;
  margin-bottom: 1.6rem !important;
  font-weight: bold;
}

.ant-dropdown .ant-dropdown-menu {
  padding: 3rem 4.7rem 1.6rem 4.7rem !important;
  background-color: #f0f0f0;
}

.ant-dropdown {
  position: absolute;
  top: 7rem !important;
  left: 128rem !important;
}

.connectBtn {
  font-weight: @font-extrabold;
  &:hover {
    background-color: #67b6ff !important;
  }
}

.pokerMain {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  overflow: hidden;
  font-family: "Crimson Pro";
  display: flex;

  .pokerLeft {
    width: 32.8rem;
    height: 108rem;
    background-color: #eeeeee;
    color: #000000;
    padding-bottom: 3.2rem;

    .pokerLeftTitle {
      padding-top: 3.8rem;
      margin-left: 5.36rem;
      margin-bottom: 7.4rem;
      cursor: pointer;
      .pokerLeftTitleIcon {
        width: 4.64rem;
        height: 4.64rem;
        margin-right: 1rem;
        vertical-align: bottom;
      }
      .pokerLeftTitleText {
        font-family: "Crimson Pro";
        height: 100%;
        display: inline-block;
        font-size: 4.64rem;
        font-weight: bold;
      }
    }

    .highLightMain {
      width: 27.1rem;
      height: 3.3rem;
      line-height: 3.3rem;
      background-color: #ffffff;
      font-size: 2.4rem;
      padding-left: 2.8rem;
      border-radius: 2rem;
    }

    .highLightSub {
      margin-top: 1rem;
      width: 24rem;
      height: 3.3rem;
      line-height: 3.3rem;
      padding-left: 0.9rem;
      background-color: #ffffff;
      border-radius: 2rem;
      font-size: 1.9rem;
      margin-left: 5rem;
    }

    .normalSub {
      margin-left: 5.6rem;
      font-size: 1.9rem;
      margin-top: 1rem;
      color: gray;
    }

    .normalMain {
      color: gray;
      margin-top: 3rem;
      font-size: 2.4rem;
      margin-left: 2.8rem;
    }

    .pokerLeftBtnContainer {
      text-align: center;
      .pokerLeftBtn {
        display: inline-block;
        margin-top: 6.3rem;
      }
    }
  }

  .pokerRight {
    width: 159.2rem;
    font-size: 1.9rem;
    .pokerRightTitle {
      font-size: 3rem;
      color: #000000;
      margin-left: 6rem;
      margin-top: 5rem;
      margin-bottom: 6.8rem;
    }

    .porkerRightFirst {
      margin-top: 7.2rem;
      color: #000000;
      .pokerRightFirstTitle {
        margin-left: 36.8rem;
        font-weight: bold;
      }

      .pokerRightFirstMain {
        display: flex;
        margin-left: 6rem;
        .userPokeTitle {
          margin-top: 1rem;
        }

        .userPokePic {
          margin-top: 1rem;
          .userPokePicItem {
            width: 12rem;
            height: 17rem;
            margin-right: 2rem;
          }
        }

        .amountContainer {
          margin-top: 2rem;
          .fontBold {
            font-weight: bold;
          }
        }

        .pokerRightFirstSubTitle {
          margin-left: 3rem;
          margin-top: 1rem;
        }

        .porkerRightPorker {
          margin-left: 3rem;
          display: flex;
          margin-top: 1rem;

          .porkerRightPorkerPic {
            width: 12rem;
            height: 17rem;
            margin-right: 2rem;
          }
        }
      }
    }

    .pokerRightSecond {
      overflow-y: auto;
      margin-top: 3rem;
      margin-left: 6rem;
      margin-right: 2rem;
      height: 52rem;

      .otherUserPic {
        display: flex;
        .otherUserPicItem {
          width: 12rem;
          height: 17rem;
          margin-right: 2rem;
        }
      }

      .contentRow {
        white-space: pre-wrap;
        margin-bottom: 2rem;
      }

      .contentRowTitle {
        font-weight: bold;
        margin-top: 0.4rem;
        margin-bottom: 0.4rem;
      }
    }

    .bottomButtonContainer {
      margin-top: 2rem;
      text-align: center;
      .bottomButton {
        display: inline-block;
        padding: 1.4rem 11.9rem;
        background-color: #ffffff;
        border: 0.13rem solid #000000;
        border-radius: 0.6rem;
        color: #000000;
        font-size: 1.9rem;
        cursor: pointer;
        text-align: center;
      }
    }
  }
}

.connectBtnClicked {
  font-family: "InriaSans-Bold";
  color: #ffffff;
  font-size: 2.98rem;
  margin-top: 1rem;
  margin-left: 6rem;
  margin-right: 8.4rem;
  cursor: pointer;
}

.underline {
  width: 100%;
  height: 1px;
  background-color: #ffffff;
}

.connectBtnClickedBlack {
  font-family: "InriaSans-Bold";
  color: #000000;
  font-size: 2.98rem;
  margin-top: 1rem;
  margin-left: 6rem;
  margin-right: 8.4rem;
  cursor: pointer;
}

.underlineBlack {
  width: 100%;
  height: 1px;
  background-color: #000000;
}
